import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navbar"
import NavbarMobile from "../components/navbar/navbarMobile"
import Marcacao from "../components/consulta/marcacao"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Consulta | Carreira Dental Clinic" />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <Marcacao />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <Marcacao />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default IndexPage

export const Json = graphql`
  query consulta {
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
